<template>
  <div>
    <main>
      <!-- 麵包屑 -->
      <section class="container" data-aos="fade-down" data-aos-delay="500">
        <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb" class="badge">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
            <li class="breadcrumb-item" aria-current="page"><router-link to="youngdad-2">我意外懷孕了</router-link></li>
            <li class="breadcrumb-item" aria-current="page"><router-link to="youngdad-3-4">我不知道該怎麼做</router-link></li>
            <li class="breadcrumb-item active" aria-current="page"><router-link to="">親密關係</router-link></li>
          </ol>
        </nav>
      </section>

      <!-- 步驟選項 -->
      <section class="container step-rwd">
        <div class="step">
          <!-- 裝飾用圖片 -->
          <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
          <img class="image1" src="../../assets/image/front/page02_image1.svg">
          <img class="image2" src="../../assets/image/front/page02_image2.svg">
          <img class="image3" src="../../assets/image/front/page02_image3.svg">
          <!-- 結束 -->
          <p class="step-text" data-aos="fade-up">STEP 03</p>
          <h2 class="step-title" data-aos="fade-up">責任・承擔</h2>
          <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up">
          <p class="step-sologan" data-aos="fade-up">交往的過程中，一定會想和情人有進一步的接觸，<br>
          在發生性行為前，要注意什麼呢？你做好準備了嗎？
          </p>
          <div class="container step-box">
            <div class="step-box-inner">
              <div class="box-title" data-aos="fade-up">
                <h2>｜親密關係｜</h2>
              </div>
              <ul class="step-menu">
                <li data-aos="fade-up" data-aos-delay="300">
                  <img src='../../assets/image/front/page02_pic1.jpg'>
                  <router-link to="intimacy-4-1">15 歲可以買保險套嗎</router-link>
                  <p class="d-block">我想到藥局買保險套，15歲可以買嗎？</p>
                </li>
                <li data-aos="fade-up" data-aos-delay="600">
                  <img src='../../assets/image/front/page02_pic2.jpg'>
                  <router-link to="intimacy-4-2">可以買事後避孕藥嗎</router-link>
                  <p class="d-block">我可以去藥局買事後避孕藥嗎？</p>
                </li>
                <li data-aos="fade-up" data-aos-delay="900">
                  <img src='../../assets/image/front/page02_pic3.jpg'>
                  <router-link to="intimacy-4-3">15歲可以買避孕藥嗎</router-link>
                  <p class="d-block">我現在15歲，<br>可以自行去診所或是藥局購買事前避孕藥嗎？</p>
                </li>
                <li data-aos="fade-up" data-aos-delay="300">
                  <img src='../../assets/image/front/page02_pic1.jpg'>
                  <router-link to="intimacy-4-4">懷孕問題</router-link>
                  <p class="d-block">只在陰道口外面摩擦會懷孕嗎？<br>
                  隔著內褲愛撫還會懷孕嗎？<br>體外射精、
                  安全期、月經來時發生性行為，<br>
                  是不是不會懷孕？</p>
                </li>
                <li data-aos="fade-up" data-aos-delay="300">
                  <img src='../../assets/image/front/page02_pic1.jpg'>
                  <router-link to="intimacy-4-5">辨識情人需求</router-link>
                  <p class="d-block">如何辨視你的情人也想要？<br>
                  要拒絕可以嗎？<br></p>
                </li>
              </ul>
              <hr data-aos="fade-up">
              <div data-aos="fade-up">
                <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Circle></Circle>
    </main>
  </div>
</template>
